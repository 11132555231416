body {
  width: 100vw;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.checkmark {
  width: 112px;
  height: 112px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: black;
  stroke-miterlimit: 10;
  -webkit-box-shadow: inset 0px 0px 0px #87f1ff;
          box-shadow: inset 0px 0px 0px #87f1ff;
  -webkit-animation: fill 0.4s ease-in-out 0.4s forwards,
 scale 0.3s ease-in-out 0.9s both;
          animation: fill 0.4s ease-in-out 0.4s forwards,
 scale 0.3s ease-in-out 0.9s both;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #87f1ff;
  fill: none;
  -webkit-animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
          animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark__check {
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  -webkit-animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
          animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@-webkit-keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes scale {
  0%,
  100% {
    -webkit-transform: none;
            transform: none;
  }
  50% {
    -webkit-transform: scale3d(1.1, 1.1, 1);
            transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes scale {
  0%,
  100% {
    -webkit-transform: none;
            transform: none;
  }
  50% {
    -webkit-transform: scale3d(1.1, 1.1, 1);
            transform: scale3d(1.1, 1.1, 1);
  }
}

@-webkit-keyframes fill {
  100% {
    -webkit-box-shadow: inset 0px 0px 0px 60px #87f1ff;
            box-shadow: inset 0px 0px 0px 60px #87f1ff;
  }
}

@keyframes fill {
  100% {
    -webkit-box-shadow: inset 0px 0px 0px 60px #87f1ff;
            box-shadow: inset 0px 0px 0px 60px #87f1ff;
  }
}
/*# sourceMappingURL=extension.css.map */