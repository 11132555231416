@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap");

p {
  font-family: "Montserrat";
  color: white;
}

h1 {
  font-family: "Montserrat";
  color: white;
}

h2 {
  font-family: "Montserrat";
  color: white;
}

.underline {
  text-decoration: underline solid #87f1ff !important;
}

th {
  padding: 0.7rem;
}

td {
  text-align: center;
  padding: 0.7rem;
}
